// Navbar.js
import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { XMarkIcon, Bars2Icon } from '@heroicons/react/24/solid';

const Navbar = ({ selectedCategory, setSelectedCategory, categories }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsMenuOpen(false); // Close the menu after selection
    // Update the URL with the selected category
    navigate(`/?category=${encodeURIComponent(category)}`);
  };

  return (
    <nav className="bg-gray-800 text-white fixed top-0 left-0 right-0 z-10">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          
            <div className="ml-10 flex items-baseline space-x-4">
              {/* Navigation Links */}
              {/* <Link to="#" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 transition duration-300">Home</Link>
                <Link to="#" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 transition duration-300">Shop</Link>
                <Link to="#" className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 transition duration-300">About</Link> */}
            </div>

            <div className="text-center">
              {/* Logo or Brand Name */}
              <Link to="/">
                <h1 className="text-xl md:text-2xl font-bold hover:text-gray-300 transition duration-300 uppercase font-tiemann">Italian Luxury Interiors</h1>
              </Link>
            </div>
          <div className="">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none hover:bg-gray-700 transition duration-300">
              {isMenuOpen ? <XMarkIcon className="block h-6 w-6" /> : <Bars2Icon className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className={`${isMenuOpen ? 'block' : 'hidden'} overflow-auto h-[90vh]`} >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {/* Category anchors for mobile */}

          {categories.map((category) => (
            <a
              key={category}
              href="#"
              onClick={() => handleCategorySelect(category)}
              className={`block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 transition duration-300 ${selectedCategory === category ? 'bg-gray-700' : ''}`}
            >
              {category.replace('In Stock', '')}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

