// Login.js
import React from 'react';

const Login = () => {
  const handleGoogleLogin = () => {
    // Redirect to the backend route that initiates the Google OAuth flow
    window.location.href = '/oauth/login/google';
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-xs">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <button
              onClick={handleGoogleLogin}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            >
              Sign in with Google
            </button>
          </div>
          {/* Add other OAuth provider buttons here */}
        </div>
      </div>
    </div>
  );
};

export default Login;
