// ProductList.js
import React from 'react';
import ProductItem from './ProductItem';
import InfiniteScroll from 'react-infinite-scroll-component';

const ProductList = ({ products, fetchMoreProducts, hasMore }) => {
  return (
    <InfiniteScroll
      dataLength={products.length}
      next={fetchMoreProducts}
      hasMore={hasMore}
      className="p-4 pl-5"
      loader={
        <div className="flex h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black mx-auto mt-36"></div>
        </div>
      }
      endMessage={
        <p style={{ textAlign: 'center' }}>
          {/* <b>Yay! You have seen it all</b> */}
        </p>
      }
    >
      {/* Use Tailwind's grid classes to create a responsive grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4">
        {products.map((product, index) => (
          <ProductItem key={index} product={product} />
        ))}
      </div>
    </InfiniteScroll>
  );
};

export default ProductList;
