import React, { useState, useEffect } from 'react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set up event listener
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // Scroll to top smooth function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="fixed bottom-4 right-12">
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="bg-black text-white p-2 rounded-full shadow-lg hover:bg-gray-700 transition duration-300 ease-in-out"
          aria-label="Go to top"
          title="Back to top"
        >
          <ChevronUpIcon className="h-6 w-6 md:h-8 md:w-8" />
        </button>
      )}
    </div>
  );
};

export default BackToTop;
