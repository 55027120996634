import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import './App.css';
import ProductList from './ProductList';
import Navbar from './Navbar';
import ProductPage from './ProductPage';
import BackToTop from './BackToTop';
import Login from './Login';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const category = query.get('category') || 'All';
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [prevCategory, setPrevCategory] = useState(null);
  const step = 200; // Number of products to load each time

  const categories = ['All', 'In Stock Armchairs', 'In Stock Beds and Bedding Accessories', 'In Stock Bookcases', 'In Stock Chairs', 'In Stock Coffee Tables', 'In Stock Cupboards', 'In Stock Floor Lamps', 'In Stock Furniture', 'In Stock Kitchen Accessories', 'In Stock Lighting', 'In Stock Outdoor Armchairs', 'In Stock Outdoor Chairs', 'In Stock Outdoor Furniture', 'In Stock Outdoor Lamps', 'In Stock Outdoor Sofas', 'In Stock Outdoor Tables', 'In Stock Sofas', 'In Stock Suspension Lamps', 'In Stock Table Lamps', 'In Stock Tables'];

  const fetchProducts = async () => {

    const currentPage = Math.floor(products.length / step) + 1;
    const queryParams = new URLSearchParams({
      page: currentPage,
      size: step,
      category: selectedCategory
    });
    const route = `/api/products?${queryParams.toString()}`;
    const response = await fetch(route);
    const data = await response.json();
    setProducts(prevProducts => {
      return [...prevProducts, ...data];
    });
    setHasMore(data.length > 0);
    setPrevCategory(selectedCategory);
    if (location.pathname === '/') {
      if (selectedCategory !== 'All') {
        navigate(`?category=${selectedCategory}`);
      } else {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    if (selectedCategory !== prevCategory) {
      setProducts([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (products.length === 0 && selectedCategory !== prevCategory) {
      fetchProducts();
    }
  }, [products]);

  return (
    <div className="App pt-16">
      <Navbar
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categories={categories}
      />
      <Routes>
        <Route path="/" element={
            <ProductList products={products} fetchMoreProducts={fetchProducts} hasMore={hasMore} />
        } />
        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <BackToTop />
    </div>
  );
}

export default App;
