// ProductItem.js
import React, { useState } from 'react';
import { LinkIcon, ArrowUpRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Dialog } from '@headlessui/react';
import ProductDetails from './ProductDetails';


const ProductItem = ({ product }) => {
    const [showModal, setShowModal] = useState(false);

    const [isCopied, setIsCopied] = useState(false);

    const copyProductLink = () => {
        const productLink = `${window.location.origin}/product/${product.id}`;
        navigator.clipboard.writeText(productLink).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset the state after 2 seconds
        });
    };

    const openProductPage = () => {
        const productPage = `/product/${product.id}`;
        window.open(productPage, '_blank');
    };



    return (
        <div className="border p-4 rounded shadow-lg m-2">
            <div className="flex flex-col justify-between h-full">
                {/* Product image and details */}
                <div className="w-full h-64 overflow-hidden rounded border-b-2 border-red-50">
                    {/* Display the first image or a placeholder if no images are available */}
                    <img src={product['Image_URLs'] ? JSON.parse(product['Image_URLs'].replace(/'/g, '"'))[0] : 'placeholder-image-url'} alt={product.Title} className="w-full h-full object-cover" />
                </div>
                <div className="flex-grow pt-1">
                    {/* Product brand and title */}
                    <h4 className="text-sm md:text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-400 via-gray-300 to-gray-500 font-righteous">{product.Brand}</h4>
                    <h3 className="text-xl md:text-2xl font-bold mb-2 truncate" title={product.Title}>{product.Title}</h3>
                    {/* Short description with ellipsis removed */}
                    <p className="text-sm md:text-base text-gray-600">{product['Short_Description'].replace('... Read all', '...')}</p>
                </div>
                {/* Button to show modal with product details */}
                <button
                    className="bg-black text-white py-3 px-6 rounded hover:bg-opacity-90 transition duration-300 mt-4 w-3/5 mx-auto font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                    onClick={() => setShowModal(true)}
                >
                    View Details
                </button>
            </div>

            {/* Modal for product details */}
            <Dialog open={showModal} onClose={() => setShowModal(false)} className="fixed inset-0 z-10 overflow-y-auto pt-16">
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <div className="relative bg-white rounded w-full max-w-7xl mx-auto p-6">
                        <div className="flex flex-row absolute top-0 right-0 m-4">
                            <button
                                onClick={copyProductLink}
                                className={`mr-4 p-1 rounded ${isCopied ? 'bg-gray-200' : ''}`}
                                title={isCopied ? 'Copied!' : 'Copy link'}
                            >
                                <LinkIcon className="h-6 w-6" />
                            </button>
                            <button onClick={openProductPage} className=" mr-4" title="Open Product Page">
                                <ArrowUpRightIcon className="h-6 w-6" />
                            </button>
                            <button onClick={() => setShowModal(false)} title="Close">
                                <XMarkIcon className="h-6 w-6" />
                            </button>

                        </div>
                        {/* ProductDetails component */}
                        <ProductDetails product={product} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ProductItem;
