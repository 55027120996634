import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductDetails from './ProductDetails';

const ProductPage = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchProduct = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`/api/product/${productId}`);
                const data = await response.json();
                setProduct(data);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
            setIsLoading(false);
        };

        fetchProduct();
    }, [productId]);

    if (isLoading) {
        return <div className="flex h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black mx-auto mt-36"></div>
        </div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <div className="p-4">
            <ProductDetails product={product} />
        </div>
    );
};

export default ProductPage;
